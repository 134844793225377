import styled from 'styled-components'

export const Container = styled.section`
  margin-top: -75px;
  padding-top: 75px;
  padding-bottom: 109px;
  background: #ae9962;
  display: flex;
  flex-direction: column;

  h1,
  p {
    font-family: Gotham Light;
    color: #fff;
    font-weight: normal;
  }

  h1 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 52px;
  }

  p {
    font-size: 15px;
    line-height: 28px;
  }

  @media (max-width: 668px) {
    margin-top: -118px;
  }

  @media (max-width: 768px) {
    padding-top: 35px;
    padding-bottom: 35px;

    p {
      line-height: 24px;
    }
  }
`

export const Inner = styled.div`
  width: 100%;
  max-width: 920px;
  margin: auto;

  @media (max-width: 1048px) {
    padding: 0px 15px;
  }
`
