import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Banner from '../components/Banner'
import SobreNos from '../sections/QuemSomos/SobreNos'
import Caracteristicas from '../sections/QuemSomos/Caracteristicas'

const QuemSomos = () => (
  <Layout>
    <SEO title="Quem Somos | Master International" />
    <Banner pageTitle="Quem somos" height={250} filename="quem-somos-banner" />
    <SobreNos />
    <Caracteristicas />
  </Layout>
)

export default QuemSomos
