import React from 'react'
import { Container, Inner, Text } from './styles'

function SobreNos() {
  return (
    <Container>
      <Inner>
        <h1>QUEM SOMOS</h1>
        <p>
          A Care Plus fornece soluções de saúde por meio de uma ampla gama de
          produtos (medicina, odontologia, saúde ocupacional e prevenção), com
          forte desempenho no segmento premium. É a principal companhia de
          planos de saúde premium no Brasil, fundada há 25 anos.
        </p>
        <br />
        <p>
          A Care Plus oferece planos de saúde diferenciados para mais de 400
          empresas e cerca de 100 mil beneficiários. A Bupa Global adquiriu a
          Care Plus em dezembro de 2016 para atender ao crescimento do mercado
          brasileiro de saúde.
        </p>
        <br />
        <p>
          A Bupa Global é o braço internacional de seguros de saúde da Bupa.
          Oferecemos em todo o mundo produtos e serviços para clientes
          conectados e com mentalidade global que querem a cobertura mais
          premium e acesso aos cuidados de saúde de que precisam a qualquer
          momento, em qualquer lugar do mundo, seja em casa, seja quando
          estudam, vivem, viajam ou trabalham no exterior.
        </p>
        <br />
        <p>
          A Bupa Global é o braço internacional de seguros de saúde da Bupa.
          Oferecemos em todo o mundo produtos e serviços para clientes
          conectados e com mentalidade global que querem a cobertura mais
          premium e acesso aos cuidados de saúde de que precisam a qualquer
          momento, em qualquer lugar do mundo, seja em casa, seja quando
          estudam, vivem, viajam ou trabalham no exterior. A Bupa Global tem
          escritórios em todo o mundo, incluindo Londres e Brighton (Reino
          Unido), Miami (EUA), Copenhague (Dinamarca), Dubai (Emirados Árabes
          Unidos, em parceria com a OIC) e Hong Kong (China), bem como
          escritórios regionais na China Continental, Cingapura, Egito, México,
          República Dominicana, Bolívia, Brasil, Panamá, Guatemala e Equador. A
          Bupa Global adquiriu recentemente a Care Plus, operadora de saúde
          líder de mercado no Brasil, que atende a mais de 400 empresas com
          cerca de 100.000 beneficiários.
        </p>
        <br />
        <p>
          Desde 1929, as empresas Blue Cross Blue Shield (BCBS) fornecem
          cobertura de saúde a segurados nos Estados Unidos, permitindo-lhes
          viver sem preocupações e sem medo. As empresas Blue Cross Blue Shield
          oferecem abordagens personalizadas na saúde baseadas nas necessidades
          das comunidades onde seus segurados vivem e trabalham. Eles trabalham
          em estreita colaboração com hospitais e médicos nas cuidados de saúde
          acessíveis e de qualidade.
        </p>
        <br />
        <p>
          As empresas Blue Cross Blue Shield entendem e respondem às
          necessidades das comunidades locais, ao mesmo tempo que fornecem
          cobertura nacional de saúde que abre portas para mais de 106 milhões
          de membros nos Estados Unidos. Em todo o país, mais de 96% dos
          hospitais e 95% dos médicos e especialistas têm contrato com empresas
          Blue Cross Blue Shield mais do que qualquer outra seguradora.
        </p>
      </Inner>
    </Container>
  )
}

export default SobreNos
