import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Banner, Inner, Block, Texts, Text } from './styles'

function Caracteristicas() {
  const { file } = useStaticQuery(graphql`
    {
      file(
        relativeDirectory: { eq: "quem-somos" }
        name: { eq: "quem-somos-banner-2" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Banner Tag="div" fluid={file.childImageSharp.fluid}>
        <Inner>
          <Block>
            <h2>BUPA GLOBAL E BLUE CROSS BLUE SHIELD GLOBAL</h2>
            <p>Os cuidados de saúde quando e onde você precisa.</p>
          </Block>
        </Inner>
      </Banner>
      <Texts>
        <Text>
          <div>
            <p>
              Dois dos nomes mais respeitados em saúde, Bupa Global e Blue Cross
              Blue Shield Global, se uniram para fornecer produtos e serviços de
              alta qualidade para cuidados de saúde. Os clientes terão acesso às
              maiores redes de prestadores de saúde em todo o mundo, utilizando
              as redes Blue Cross Blue Shield Global nos EUA e Bupa fora dos
              EUA, sujeitas a certas restrições e limitações.
            </p>
            <br />
            <p>
              Por meio da Blue Cross Blue Shield Global e da Bupa Global, os
              membros recebem um cartão de identificação Blue Cross Blue Shield
              Global que dará acesso à rede Blue Cross Blue Shield nos EUA,
              apoio em um centro de serviços dos EUA e acesso ao programa de
              descontos em farmácias nos EUA. A Blue Cross Blue Shield Global e
              a Bupa continuarão a expandir e fortalecer sua colaboração em todo
              o mundo para oferecer valor por meio de produtos e serviços de
              seguros para beneficiar clientes em todo o mundo
            </p>
          </div>
        </Text>
        <Text>
          <div>
            <p>
              Care Plus é a designação comercial da Care Plus Medicina
              Assistencial Ltda., o operador deste plano. BUPA Global é o nome
              comercial de BUPA, a empresa internacional de saúde e cuidados.
              BUPA é uma licenciada independente da Blue Cross Blue Shield
              Association. BUPA Global não é licenciada pela Blue Cross Blue
              Shield Association para vender produtos dessa marca em Anguilla,
              Argentina, Ilhas Virgens Britânicas, Canadá, Costa Rica, Panamá,
              Uruguai e Ilhas Virgens dos EUA. Em Hong Kong, BUPA Global só é
              licenciada para usar as marcas Blue Shield. Consulte as condições
              gerais do seu plano para a disponibilidade da cobertura.
            </p>
            <br />
            <p>
              Blue Cross Blue Shield Association é uma Federação Nacional de 36
              empresas independentes, de base comunitária e localmente operadas
              pela Blue Cross Blue Shield. Blue Cross Blue Shield Global é uma
              marca de propriedade da Blue Cross Blue Shield Association. Para
              obter mais informações sobre BUPA Global, visite
              bupaglobalaccess.com e para obter mais informações sobre Blue
              Cross Blue Shield Association, visite www.bcbs.com
            </p>
          </div>
        </Text>
      </Texts>
    </Container>
  )
}

export default Caracteristicas
