import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

export const Container = styled.section`
  width: 100%;
  height: auto;
`

export const Banner = styled(BackgroundImage)`
  display: flex;
  align-items: center;
  height: 543px;

  @media (max-width: 868px) {
    height: 453px;
  }
`

export const Inner = styled.div`
  width: 100%;
  max-width: 1045px;
  margin: auto;

  @media (max-width: 1048px) {
    padding: 0px 15px;
  }
`

export const Block = styled.div`
  background: #ae9962;
  padding: 33px 23px;
  width: 374px;
  height: 374px;
  display: flex;
  flex-direction: column;
  padding-right: 116px;

  h2,
  p {
    color: #fff;
    font-weight: normal;
  }

  h2 {
    font-size: 28px;
    line-height: 34px;
    font-family: Gotham Light;
    margin-bottom: 41px;
  }

  p {
    font-size: 15px;
  }
`

export const Texts = styled.div``

export const Text = styled.div`
  width: 100%;
  padding-bottom: 79px;
  padding-top: 96px;

  div {
    width: 100%;
    max-width: 920px;
    margin: auto;

    p {
      font-size: 15px;
      line-height: 28px;
      color: #333333;
    }
  }

  &:last-of-type {
    background: #eef5f9;
    margin-bottom: -98px;
  }

  @media (max-width: 1048px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 768px) {
    padding: 35px 15px;

    div p {
      line-height: 24px;
    }
  }
`
